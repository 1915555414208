
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Contact } from '@/modules/contact/types';
import { ApiResponse } from '@/components/types';
import { FETCH_CONTACTS, GET_CONTACTS } from '@/modules/conservatorshipToContact/store';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const Conservatorship = namespace('conservatorshipToContact');

@Component({ inheritAttrs: false })
export default class DoctorSelect extends Vue {
  @Prop({ type: String }) value?: string | Contact;
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) showOnlyWithValues!: boolean;

  @Conservatorship.Getter(GET_CONTACTS) getByConservatorship!: (id: string) => Contact[];
  @Conservatorship.Action(FETCH_CONTACTS) fetchContacts!: (id: string) => Promise<ApiResponse>;

  get contact(): string | Contact | undefined {
    return this.value;
  }

  set contact(value: string | Contact | undefined) {
    this.$emit('input', value);
  }

  get list() {
    return this.getByConservatorship(this.conservatorship.id)
      .filter((contact) => contact.types.includes('doctor'))
      .sort((first, second) => first.name.localeCompare(second.name));
  }

  created() {
    return this.fetchContacts(this.conservatorship.id);
  }
}
