import { ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';

export const createReport = (report_id: string): ReportOfConservatorshipManagement => ({
  report_id,
  start: '',
  end: '',
  financial_administration: true,
  conservatee: {
    name: '',
    birthday: '',
    attorney_docket: ''
  },
  conservator: {
    name: '',
    phone_number: ''
  },
  address: {
    name: '',
    street: '',
    city: '',
    zip_code: '',
    additional: ''
  },
  court: {
    name: '',
    street: '',
    city: '',
    zip_code: '',
    additional: ''
  },
  personal_circumstances: {
    placement: {
      address: {
        name: '',
        street: '',
        city: '',
        zip_code: '',
        additional: ''
      },
      in_asylum: false,
      in_closed_institution: false
    },
    care_management: {
      options: [],
      additional: ''
    },
    health_progress: {
      status: 'no_change',
      explanation: '',
      doctor: {
        name: '',
        street: '',
        city: '',
        zip_code: '',
        additional: ''
      }
    },
    require_scopes_of_functions: '',
    conservatorship_required: true,
    visit: {
      last_seen: '',
      interval: 'monthly',
      additional: ''
    },
    assets: {
      has_finance_management: false,
      end_balance: 0,
      first_of_january_balance: 0
    }
  },
  economic_circumstances: {
    additional: {
      to_inform: false,
      to_propose: false,
      text: ''
    },
    balance_first_of_january: {
      properties: [],
      finance_accounts: [],
      payables: {
        amount: 0,
        additional: ''
      }
    },
    end_balance: {
      properties: [],
      finance_accounts: [],
      payables: {
        amount: 0,
        additional: ''
      }
    },
    legal_proceedings: [''],
    new_assets: [''],
    revenue_usage: {
      asylum_cost: 0,
      rent: 0,
      phone_cost: 0,
      electricity_cost: 0,
      subsistence_parts: [
        { name: 'Lebensunterhaltskosten', cost: 0, id: uuid() }
      ],
      other_parts: [{ name: '', cost: 0, id: uuid() }],
      payables: [{ name: '', cost: 0, id: uuid() }]
    },
    revenue: {
      assets: [],
      target_account: '',
      custom_target: '',
      pension_target: ''
    },
    placement_cost: {
      financed_from: '',
      has_allowance: true,
      allowance_amount: 0,
      for_conservatee: false,
      balance: 0,
      date: format(Date.now(), 'yyyy-MM-dd'),
      controlled: true,
      controlled_by_copies: false,
      controlled_by_insights: true,
      used_as_intended: false
    }
  }
});
